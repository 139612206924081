<div
  class="network-alpha-prefix-dialog-container default-styles"
  ngClass.lt-md="mobile"
  data-cy="network-alpha-prefix-dialog"
>
  <div mat-dialog-title>
    <zelis-button
      forwardedClass="absolute top-0 right-0"
      variant="basic"
      mat-dialog-close
      [ariaLabel]="'app_global_close_alpha_prefix' | translate"
    >
      <mat-icon svgIcon="dls-times-plain-light"></mat-icon>
    </zelis-button>
    <div class="eyebrow separator text-center">
      {{ 'network_alpha_prefix_dialog_title' | translate }}
    </div>
  </div>
  <div mat-dialog-content>
    <!-- Top Section - Login Tips -->
    <ng-container *ngIf="!data.authStatus && !!data.loginUrl">
      <h3 class="margin bottom-3x">
        {{ 'network_alpha_prefix_dialog_section_1_title' | translate }}
      </h3>
      <div
        class="section-separator"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="start space-between"
        fxLayoutGap="32px"
      >
        <div fxFlex="50">
          <div
            class="margin bottom-3x"
            [innerHTML]="
              'network_alpha_prefix_dialog_section_1_content' | translate
            "
          ></div>
          <div
            class="flex flex-row justify-start items-center gap-4 margin bottom-2x"
          >
            <div fxFlex="nogrow">
              <div class="circle-icon">
                <mat-icon
                  svgIcon="dls-dollar-signs-circle-light"
                  class="text-black w-10 h-10"
                ></mat-icon>
              </div>
            </div>
            <div fxFlex="grow">
              {{ 'network_alpha_prefix_dialog_section_1_item_1' | translate }}
            </div>
          </div>
          <div class="flex flex-row justify-start items-center gap-4">
            <div fxFlex="nogrow">
              <div class="circle-icon">
                <mat-icon
                  class="black-svg w-10 h-10"
                  svgIcon="dls-star-empty-one"
                ></mat-icon>
              </div>
            </div>
            <div class="grow-0">
              {{ 'network_alpha_prefix_dialog_section_1_item_2' | translate }}
            </div>
          </div>
        </div>
        <div fxFlex="50">
          <zelis-button
            color="accent"
            size="large"
            variant="flat"
            fullWidth="true"
            [href]="data.loginUrl"
            data-cy="network-alpha-prefix-dialog.login-button"
          >
            {{ 'app_global_login' | translate }}
          </zelis-button>
        </div>
      </div>
    </ng-container>
    <!-- Bottom Section -->
    <h3 class="margin bottom-3x">
      {{ 'network_alpha_prefix_dialog_section_2_title' | translate }}
    </h3>
    <div
      fxLayout.gt-sm="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start space-between"
      fxLayoutGap="32px"
    >
      <div fxFlex="50">
        <div
          class="margin bottom-3x"
          [innerHTML]="
            'network_alpha_prefix_dialog_section_2_content'
              | translate: { length: data.alphaPrefixLength }
          "
        ></div>
        <div>
          <platform-member-card
            [suppressLogo]="suppressLogo"
            [magnify]="memberCardMagnify"
          >
          </platform-member-card>
        </div>
      </div>
      <div fxFlex="50">
        <form [formGroup]="alphaPrefixForm" (ngSubmit)="onAlphaPrefixSubmit()">
          <app-tokenized-input
            data-cy="network-alpha-prefix-dialog.platform-tokenized-input"
            appUnfocus
            [count]="data.alphaPrefixLength"
            formControlName="alphaPrefix"
            (keyup)="onAlphaPrefixChange()"
            (innerFocus)="onAlphaPrefixFocus()"
          >
          </app-tokenized-input>
          <zelis-progress-bar
            class="margin top-2x"
            *ngIf="showProgressBar"
            color="primary"
            mode="indeterminate"
          >
          </zelis-progress-bar>
          <div class="margin top-2x" *ngIf="networks?.length > 1">
            <div
              [innerHTML]="
                'network_alpha_prefix_dialog_plan_name_title' | translate
              "
            ></div>
            <div class="margin top-2x">
              <mat-form-field class="network-select full-width">
                <mat-label>{{
                  'network_alpha_prefix_dialog_plan_name_option' | translate
                }}</mat-label>
                <mat-select
                  data-cy="network-alpha-prefix-dialog.mat-select"
                  appUnfocus
                  formControlName="network"
                  (focus)="onNetworkFocus()"
                >
                  <mat-option
                    *ngFor="let network of networks"
                    [value]="network.id"
                  >
                    {{ network.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <zelis-button
            forwardedClass="mt-4"
            [fullWidth]="true"
            data-cy="network-alpha-prefix-dialog.go-button"
            type="submit"
            variant="flat"
            color="accent"
            [disabled]="alphaPrefixForm.valid === false"
          >
            {{ 'app_global_go' | translate }}
          </zelis-button>
          <div
            class="error margin top-2x"
            *ngIf="networks && networks.length === 0"
          >
            {{ 'network_alpha_prefix_dialog_no_networks_error' | translate }}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
