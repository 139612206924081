import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlashMessageComponent } from './flash-message.component';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  exports: [FlashMessageComponent],
  imports: [CommonModule, DlsIconModule],
  declarations: [FlashMessageComponent],
})
export class FlashMessageModule {}
