import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { AuthStatus } from '@zelis/platform-ui-components';
import { RouteUtilities } from '../../utilities/route.utilities';
import { LnpSettings, LnpSetting } from '@interfaces/lnp-setting.model';
import { LnpLogEvent } from '@interfaces/lnp-log-event.model';
import { LnpComponentLoadedEvent } from '@interfaces/lnp-component-loaded-event.model';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { forIn } from 'lodash';
import { LogEventService } from '@services/log-event.service';
import { AppLoaders } from '@enums/app-loaders.enum';

@Injectable({
  providedIn: 'root',
})
export class LnpService {
  public appLoadedEvent = new BehaviorSubject(null);

  private lnpSettings = new LnpSettings();
  private routeUtilities = new RouteUtilities();
  private auth: AuthStatus;
  private componentLoadedEvent = new Subject();
  private lastUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private logEventService: LogEventService
  ) {
    this.trackAppLoad();
  }

  public init(): Observable<void> {
    return this.trackRouteChange();
  }

  public triggerComponentLoaded(event: LnpComponentLoadedEvent): void {
    this.componentLoadedEvent.next(event);
  }

  public captureLoaderDisplayTime(loaderName: AppLoaders): () => void {
    const startTime = new Date().getTime();
    const logLoaderDisplayDuration: Subject<void> = new Subject();

    logLoaderDisplayDuration
      .pipe(
        switchMap(() => {
          const loaderEventData = {
            loader: loaderName,
            displayDuration: new Date().getTime() - startTime,
          };
          return this.logEventService.postLogEvent(loaderEventData);
        })
      )
      .subscribe();

    return () => {
      logLoaderDisplayDuration.next();
      logLoaderDisplayDuration.complete();
    };
  }

  private trackAppLoad(): void {
    this.appLoadedEvent.next(true);
  }

  private trackComponentLoad(
    route: string,
    path: string,
    previousPath: string,
    startTime: number
  ): void {
    this.componentLoadedEvent
      .pipe(take(1))
      .subscribe((lnpData: LnpComponentLoadedEvent) => {
        if (this.hasRouteLoaded(route, startTime, lnpData)) {
          this.postLogEvent(
            this.lnpSettings[route],
            path,
            previousPath,
            lnpData
          );
          this.lnpSettings[route] = new LnpSettings()[route];
        }
      });
  }

  private trackRouteChange(): Observable<void> {
    return this.authService.authStatus.pipe(
      filter((auth) => auth.resolved),
      tap((auth) => (this.auth = auth)),
      switchMap(() => this.router.events),
      filter(
        (event) =>
          (event instanceof NavigationStart ||
            event instanceof NavigationEnd) &&
          event.url !== '/'
      ),
      tap((event: NavigationStart) => this.onRouterEvent(event)),
      map(() => {
        return;
      })
    );
  }

  private onRouterEvent(event: NavigationStart | NavigationEnd): void {
    if (
      event instanceof NavigationStart ||
      (event instanceof NavigationEnd && !this.lastUrl)
    ) {
      this.trackComponentLoad(
        this.routeUtilities.getState(event.url),
        event.url,
        this.lastUrl || '',
        new Date().getTime()
      );
      this.lastUrl = event.url;
    }
  }

  private hasRouteLoaded(
    route: string,
    startTime: number,
    lnpData: LnpComponentLoadedEvent
  ): boolean {
    const timeToLoad = new Date().getTime() - startTime + 1;
    let out = false;

    if (this.lnpSettings[route]) {
      this.lnpSettings[route].dependencies[lnpData.component] = timeToLoad;
      if (this.haveDepsLoaded(this.lnpSettings[route])) {
        this.lnpSettings[route].loaded = true;
        out = true;
      }
    }

    return out;
  }

  private haveDepsLoaded(setting: LnpSetting): boolean {
    let out = true;

    forIn(setting.dependencies, (loadTime) => {
      if (!loadTime) {
        out = false;
      }

      if (loadTime > setting.loadTime) {
        setting.loadTime = loadTime;
      }
    });

    return out;
  }

  private postLogEvent(
    setting: LnpSetting,
    path: string,
    previousPath: string,
    lnpData: any
  ): void {
    const req = this.logEventService.postLogEvent(
      this.mapLogEventData(setting, path, previousPath, lnpData)
    );
    req.subscribe();
  }

  private mapLogEventData(
    setting: LnpSetting,
    path: string,
    previousPath: string,
    lnpData: any
  ): LnpLogEvent {
    return {
      event: {
        authenticated: this.auth.auth_status,
        path: path,
        previousPath: previousPath,
        duration: setting.loadTime,
        data: lnpData.data,
      },
    };
  }
}
