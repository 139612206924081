<div
  class="platform-expandable-section"
  [ngClass]="{
    expanded: expand,
    'direction-up': expandDirection === 'up',
    accordion: display === 'accordion',
    'card-title': display === 'card-title',
    'fix-accordion': fixed
  }"
  [fxLayout]="expandDirection === 'down' ? 'column' : 'column-reverse'"
>
  <div>
    <button
      class="section-title"
      (click)="onClick($event)"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="24px"
      [tabindex]="!!fixed ? -1 : 0"
      type="button"
    >
      <h3 class="dls-subhead-3-bold" fxFlex="grow">
        {{ expand ? expandedText : collapsedText }}
      </h3>
      <span fxFlex="nogrow" [fxHide]="fixed">
        <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
      </span>
    </button>
  </div>
  <div
    class="section-content-container"
    [@slideOpenClose]="expand ? 'opened' : 'closed'"
  >
    <div class="section-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
