<span
  [@fadeInOut]="trigger"
  [class]="type + '-message flex justify-center items-center'"
  role="alert"
>
  <mat-icon
    [svgIcon]="iconClass"
    class="scale-150 mr-4"
    aria-hidden="true"
  ></mat-icon>
  {{ message }}
</span>
