import {
  Component,
  ViewChild,
  OnDestroy,
  Input,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { GuidedSearchService } from '@services/guided-search/guided-search.service';
import { GuidedSearchSetting } from '@interfaces/guided-search-setting.model';
import { TermAutosuggest } from '@classes/term-autosuggest.class';
import { AlphaListService } from '@services/alpha-list/alpha-list.service';
import { CcssService } from '@services/ccss/ccss.service';
import { Breakpoints } from '@classes/breakpoints.class';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { GoogleTagManagerService } from '@services/analytics/google-tag-manager.service';
import { Router } from '@angular/router';
import { GuidedSearchType } from '@interfaces/guided-search-type.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { AuthStatus } from '@interfaces/auth-status.model';
import { RouteUtilities } from '@utilities/route.utilities';
import { MembersService } from '@services/members.service';
import { DefaultMember } from '@interfaces/default-member.model';
import { DialogService } from '@zelis/dls/dialog';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GuidedSearchDialogComponent } from '@components/guided-search/guided-search-dialog/guided-search-dialog.component';

@Component({
  selector: 'app-guided-search',
  templateUrl: './guided-search.component.html',
  styleUrls: ['./guided-search.component.scss'],
})
export class GuidedSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public selectedOption: GuidedSearchSetting;
  @Input() public data: any;

  @ViewChild('alphaList') alphaList;

  public guidedSearchList: Observable<GuidedSearchSetting>;
  public breadcrumbList: Observable<GuidedSearchSetting[]>;
  public selectedOptionChanged: BehaviorSubject<GuidedSearchSetting> =
    new BehaviorSubject(null);
  public trackByName: any = TrackByUtilities.getTrackByFn('name');
  public authStatus: AuthStatus;
  public defaultMember: boolean;
  public qualityRewardsEligible: boolean;

  private type: GuidedSearchType;
  private breadcrumbs: BehaviorSubject<GuidedSearchSetting[]> =
    new BehaviorSubject([]);
  private subscriptions = new SubscriptionManager();

  constructor(
    public alphaListService: AlphaListService,
    public ccssService: CcssService,
    public breakpoints: Breakpoints,
    public termAutosuggest: TermAutosuggest,
    private guidedSearchService: GuidedSearchService,
    private googleTagManagerService: GoogleTagManagerService,
    private router: Router,
    private store: Store<any>,
    private routeUtilities: RouteUtilities,
    private membersService: MembersService,
    private dialogService: DialogService,
    private analyticsService: ProductAnalyticsService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<GuidedSearchDialogComponent>
  ) {
    this.guidedSearchList = this.getGuidedSearchList();
    this.breadcrumbList = this.getBreadcrumbList();
  }

  ngOnInit(): void {
    this.subscribeToGuidedSearchList();
    this.setAuth();
    this.getDefaultMember();
    this.getMember();
  }

  ngAfterViewInit(): void {
    this.setAriaLabel();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onKeyPress(option: GuidedSearchSetting): void {
    this.selectOption(option);
  }

  public selectOption(option: GuidedSearchSetting): void {
    if (option?.onClick?.name === 'onClick') {
      return option?.onClick();
    }
    if (!option) {
      this.updateSelectedOption(null);
      return;
    }

    if (option.type) {
      if (option.type === 'incentivized_specialties') {
        this.type = 'search_specialties';
      } else {
        this.type = option.type;
      }
    }

    const isProviderType =
      option.type === 'provider_types' &&
      option.name !== 'guided_search_provider_types_title';
    // If a branch, map whether it can show
    if (!option.id && !isProviderType) {
      this.updateSelectedOption(option);
      this.pushToGtm(option.constructor.name, 'option');
      return;
    }

    if (isProviderType) {
      this.termAutosuggest.activateSearch(
        { type: 'name', name: '*' },
        {
          ...option.filters,
          provider_type_description: encodeURIComponent(option.name),
        }
      );
    } else {
      // If a leaf (id present), go to search
      const filters = option.filters
        ? { preserveFilters: true, ...option.filters }
        : {};

      this.termAutosuggest.activateSearch(
        { type: this.type, id: option.id },
        filters
      );

      if (this.type === 'incentivized_procedures') {
        this.trackIncentivezedSelection(option);
      }

      if (this.type === 'search_specialties' && option.high_incentive_amount) {
        this.trackQualityRewardsSelection(option);
      }

      // Capture terminal 3 selection and pass it to the Pendo tracker
      this.trackThirdTerminalSelection(option);
    }

    this.trackGuidedSearchClickPath(option);
    this.dialogService.closeAll();
    this.pushToGtm(option.constructor.name, 'terminal.option');
  }

  private trackGuidedSearchClickPath(option: GuidedSearchSetting) {
    this.analyticsService.sendTrackEvent('Browse By Category', {
      browse_search_card_click_event: !!this.data?.cardOrder,
      card_ordering: this.data?.cardOrder,
      search_method_click_path:
        this.breadcrumbs
          .getValue()
          .map((crumb) => this.translateService.instant(crumb.name))
          .join(' > ') +
        ' > ' +
        this.translateService.instant(option.name),
    });
  }

  private trackThirdTerminalSelection(option: GuidedSearchSetting): void {
    // Note: Browse by Category Tile selection counts as terminal 1 and is not part of the breadcrumbs array
    const terminals = this.breadcrumbs.getValue();
    if (terminals.length === 2) {
      let description: string;
      if (typeof option.description === 'object') {
        const isNotEmpty: boolean = !!Object.keys(option.description).length;
        description = isNotEmpty
          ? (option.description as { en: string; es: string }).en
          : '';
      } else {
        if (!!option.description) {
          description = this.translateService.instant(option.description);
        } else {
          description = '';
        }
      }

      this.analyticsService.sendTrackEvent('Browse by Category Terminal 3', {
        selection_title: this.translateService.instant(option.name),
        selection_description: description,
        breadcrumbs: terminals.map((crumb) =>
          this.translateService.instant(crumb.name)
        ),
      });
    }
  }

  private trackIncentivezedSelection(option: GuidedSearchSetting): void {
    const procedureName = this.translateService.instant(option.name).trim();
    this.analyticsService.sendTrackEvent(
      'Member Rewards Incentives | Procedure Search',
      {
        searchTerm: procedureName,
        procedureName: procedureName,
        searchMethod:
          'Browse By Category > ' +
          this.breadcrumbs
            .getValue()
            .map((crumb) => this.translateService.instant(crumb.name))
            .join(' > '),
        rewardAmount: option.high_incentive_amount,
      }
    );
  }

  private trackQualityRewardsSelection(option: GuidedSearchSetting): void {
    const specialtyName = this.translateService.instant(option.name).trim();
    this.analyticsService.sendTrackEvent(
      'Quality Rewards Incentive | Specialty Search',
      {
        searchTerm: specialtyName,
        specialtyName: specialtyName,
        searchMethod:
          'Browse By Category > ' +
          this.breadcrumbs
            .getValue()
            .map((crumb) => this.translateService.instant(crumb.name))
            .join(' > '),
        rewardAmount: option.high_incentive_amount,
      }
    );
  }

  private subscribeToGuidedSearchList(): void {
    // Select the first option if none selected
    this.subscriptions.add(
      this.guidedSearchList
        .pipe(take(1))
        .subscribe((list) => this.selectOption(this.selectedOption || list))
    );
  }

  private getGuidedSearchList(): Observable<GuidedSearchSetting> {
    return this.guidedSearchService
      .getSelectedGuidedSearchOption(
        this.guidedSearchService.guidedSearchOptions,
        this.selectedOptionChanged
      )
      .pipe(
        map((guidedSearchSetting: GuidedSearchSetting) =>
          this.suppressUnauthenticatedOption(guidedSearchSetting)
        )
      );
  }

  private getBreadcrumbList(): Observable<GuidedSearchSetting[]> {
    return this.breadcrumbs.pipe(
      map((breadcrumbs) => breadcrumbs.slice(0, -1))
    );
  }

  private pushToGtm(name: string, optionType: string): void {
    this.googleTagManagerService.pushToDataLayer({
      event: 'gtm.guided_search.select.' + optionType,
      guided_search_option_type: this.onIncentivizedList()
        ? 'incentivized_procedures'
        : this.type,
      guided_search_option_class: name,
    });
  }

  private onIncentivizedList(): boolean {
    return this.alphaList?.listName === 'incentivized_procedures';
  }

  private updateSelectedOption(option: GuidedSearchSetting): void {
    this.selectedOptionChanged.next(option);
    this.updateBreadcrumbs(option);
  }

  private updateBreadcrumbs(option: GuidedSearchSetting): void {
    if (!option) {
      return;
    }
    let crumbs = this.breadcrumbs.getValue();
    const index = crumbs.findIndex((crumb) => crumb?.name === option?.name);
    if (index > -1) {
      crumbs = crumbs.slice(0, index + 1);
    } else {
      crumbs.push(option);
    }
    this.breadcrumbs.next(crumbs);
  }

  private setAuth(): void {
    this.subscriptions.add(
      this.store
        .pipe(select(AuthStoreSelectors.getAuthStatus))
        .subscribe((auth: AuthStatus) => (this.authStatus = auth))
    );
  }

  private suppressUnauthenticatedOption(
    setting: GuidedSearchSetting
  ): GuidedSearchSetting {
    if (setting?.options) {
      const pageState = this.routeUtilities.getState();
      setting.options = setting.options.filter(
        (option) =>
          !(
            !this.defaultMember &&
            option.unauthenticatedProcedures &&
            pageState !== 'home'
          )
      );
      return setting;
    }
  }

  private getDefaultMember(): void {
    this.subscriptions.add(
      this.membersService.defaultMember.subscribe((member: DefaultMember) => {
        this.defaultMember = member?.inAllowedLocation;
      })
    );
  }

  private getMember(): void {
    this.subscriptions.add(
      this.membersService.member
        .pipe(first((member) => !!member))
        .subscribe((member) => {
          this.qualityRewardsEligible = member.quality_rewards_eligible;
        })
    );
  }

  private setAriaLabel(): void {
    this.subscriptions.add(
      this.dialogRef
        .afterOpened()
        .pipe(
          take(1),
          switchMap(() => {
            return this.guidedSearchList;
          })
        )
        .subscribe((guidedSearch) => {
          this.dialogRef._containerInstance._config.ariaLabel =
            this.translateService.instant(guidedSearch.name);
        })
    );
  }
}
