export class Procedure {
  id: number;
  name: string;
  locale_name: string;
  description: string;
  condition_code: string;
  condition: string;
  category_type_code: string;
  treatment_category_code: string;
  benefit_id: string;
  procedure_code: string;
  default_resource_type: string;
  minimum_search_radius: number;
  keywords: string[];
  is_rts?: boolean;
  rts_category?: string;
  eligible_for_incentive?: boolean;
  high_incentive_amount?: number;
  low_incentive_amount?: number;

  constructor(obj: Procedure) {
    this.id = obj.id || null;
    this.name = obj.name || '';
    this.locale_name = obj.locale_name || obj.name;
    this.description = obj.description || '';
    this.condition_code = obj.condition_code || '';
    this.condition = obj.condition || '';
    this.category_type_code = obj.category_type_code || '';
    this.treatment_category_code = obj.treatment_category_code || '';
    this.benefit_id = obj.benefit_id || '';
    this.procedure_code = obj.procedure_code || '';
    this.default_resource_type = obj.default_resource_type || '';
    this.minimum_search_radius = obj.minimum_search_radius || null;
    this.keywords = obj.keywords || null;
    this.is_rts = obj.is_rts || false;
    this.rts_category = obj.rts_category || '';
    this.eligible_for_incentive = !!obj.eligible_for_incentive;
    this.high_incentive_amount = obj.high_incentive_amount || null;
    this.low_incentive_amount = obj.low_incentive_amount || null;
  }
}
