import {
  map,
  mergeMap,
  switchMap,
  catchError,
  take,
  tap,
  startWith,
  filter,
  first,
  debounceTime,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FeaturesService } from './features/features.service';
import { ConfigurationService } from './configuration.service';
import { MleService } from './mle.service';
import { AuthService } from './auth.service';
import { AppParamsService } from './app.params.service';
import { MembersService } from './members.service';
import { SettingsService } from './settings.service';
import {
  BehaviorSubject,
  Observable,
  of,
  combineLatest,
  throwError as _throw,
  forkJoin,
  iif,
} from 'rxjs';
import { AuthStatus } from '@interfaces/auth-status.model';
import { Procedure } from '@interfaces/procedure.model';
import { Member } from '@components/members';
import { DefaultMember } from '@interfaces/default-member.model';
import { NetworksService } from './networks.service';
import { MleStatus } from '@interfaces/mle-status.model';
import { IncentivizedProcedure } from '@interfaces/incentivized-procedure.model';
import { clone, pickBy } from 'lodash';
import { HasHistoricalClaimsConfig } from '@interfaces/has-historical-claims.model';

@Injectable({
  providedIn: 'root',
})
export class ProceduresService {
  public proceduresUpdated: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );
  public procedureId: string;
  private requestedLocale = '';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private mleService: MleService,
    private translateService: TranslateService,
    private membersService: MembersService,
    private settingsService: SettingsService,
    private configurationService: ConfigurationService,
    private appParamsService: AppParamsService,
    private featuresService: FeaturesService,
    private networksService: NetworksService
  ) {}

  public getMinSearchRadius(procedureId: string): Observable<number> {
    return this.requestIncentivizedProcedure(procedureId).pipe(
      map(
        (procedure: IncentivizedProcedure) => procedure?.minimum_search_radius
      ),
      first()
    );
  }

  public requestIncentivizedProcedure(
    id: string,
    rts?: boolean
  ): Observable<IncentivizedProcedure> {
    return combineLatest([
      this.authService.authStatus,
      this.getMleStatus().pipe(
        filter((mleStatus: MleStatus) => mleStatus && mleStatus.resolved)
      ),
      this.membersService.member,
    ]).pipe(
      switchMap(
        ([authStatus, mleStatus, member]: [AuthStatus, MleStatus, Member]) => {
          if (
            !authStatus.auth_status ||
            !mleStatus.isEligible ||
            (member && !member.incentives_enabled) ||
            member === null
          ) {
            return _throw(true);
          }
          let params = this.setProcedureParams(id, null, true);
          if (rts) {
            params = params.set('is_rts', 'true');
          }
          const url = `/api/incentivized_procedures/${id}.json`;

          return this.http
            .get(url, { params: params, withCredentials: true })
            .pipe(
              map(
                (results: any) =>
                  (results && results.incentivized_procedure) || null
              )
            );
        }
      )
    );
  }

  public requestIncentivizedProcedures(
    rts?: boolean
  ): Observable<IncentivizedProcedure[]> {
    return combineLatest([
      this.authService.authStatus,
      this.getMleStatus().pipe(
        filter((mleStatus: MleStatus) => mleStatus && mleStatus.resolved)
      ),
      this.membersService.member,
    ]).pipe(
      switchMap(
        ([authStatus, mleStatus, member]: [AuthStatus, MleStatus, Member]) => {
          if (
            !authStatus.auth_status ||
            !mleStatus.isEligible ||
            (member && !member.incentives_enabled) ||
            member === null
          ) {
            return _throw(true);
          }
          let params = this.setProcedureParams(null);
          if (rts) {
            params = params.set('is_rts', 'true');
          }
          const url = `/api/incentivized_procedures.json`;

          return this.http
            .get(url, { params: params, withCredentials: true })
            .pipe(
              map(
                (results: any) =>
                  (results && results.incentivized_procedures) || null
              )
            );
        }
      ),
      catchError(() => of(null))
    );
  }

  public getProcedures(
    id?: string | string[],
    lang?: string
  ): Observable<Procedure[]> {
    return this.checkCostSuppression().pipe(
      switchMap((suppressed) =>
        iif(
          // If cost is suppressed
          () => !!suppressed,
          // Then, do not request procedures - this disables all cost features
          of([]).pipe(tap(() => this.proceduresUpdated.next([]))),
          // Else, determine whether to update procedures
          this.updateProcedures(id, lang)
        )
      )
    );
  }

  public setProcedureParams(
    id: string | string[],
    lang?: string,
    disableCache?: boolean,
    signature?: string
  ): HttpParams {
    const procedure_params =
      clone(this.appParamsService.getMemberParams()) || {};
    if (id && typeof id === 'string') {
      this.procedureId = id;
      procedure_params.procedure_id = id;
    } else if (id && id.length) {
      procedure_params['procedure_id[]'] = id;
    }
    procedure_params.locale = lang || this.setRequestLocale();
    procedure_params.account_id =
      this.configurationService.account_id.getValue();
    if (disableCache === true) {
      procedure_params.cache = 'false';
    }
    if (!!signature) {
      procedure_params.config_signature = signature;
    }
    return new HttpParams({
      fromObject: pickBy(procedure_params, (value, key) => !!value),
    });
  }

  private doProceduresHttpRequest(
    id: string | string[],
    lang: string
  ): Observable<Procedure[]> {
    return this.getSignature().pipe(
      switchMap((signature: string) => {
        const params = this.setProcedureParams(id, lang, false, signature);
        const url = `/api/procedures.json`;
        return this.http
          .get(url, { params: params, withCredentials: true })
          .pipe(
            map((results) => {
              let result = results['procedures'].map(
                (procedure) => new Procedure(procedure)
              );
              // PUI-12984 sometimes all procedures being returned even with id passed - filter if needed
              if (id && typeof id === 'string' && result.length > 1) {
                result = result.filter((p) => p.id === parseInt(id, 10));
              }
              return result;
            }),
            catchError(() => of(false))
          );
      })
    );
  }

  private shouldRequestProcedures(
    auth: AuthStatus,
    member: Member,
    defaultMember: DefaultMember,
    mleEligible: boolean
  ): boolean {
    const isLoggedIn = auth && auth.auth_status && auth.resolved;
    const isValidUnauth =
      !isLoggedIn && defaultMember && defaultMember.inAllowedLocation;
    const isValidMember = member || member === null;
    if (isValidUnauth) {
      return true;
    }
    return !!(isLoggedIn && mleEligible && isValidMember);
  }

  private setRequestLocale(lang?: string): string {
    if (lang) {
      this.requestedLocale = lang;
    } else if (this.translateService.currentLang) {
      this.requestedLocale = this.translateService.currentLang;
    } else {
      this.requestedLocale = 'en';
    }
    return this.requestedLocale;
  }

  private checkCostSuppression(): Observable<boolean> {
    return forkJoin([
      this.settingsService.getSetting('suppress_cost').pipe(first()),
      this.featuresService.getFeatureFlags(),
      this.settingsService
        .getSetting('has_historical_claims', HasHistoricalClaimsConfig)
        .pipe(first()),
    ]).pipe(
      switchMap(([suppressCostSetting, featureFlags, hasClaims]) => {
        return of(
          (featureFlags && !featureFlags.cost) ||
            suppressCostSetting ||
            !hasClaims.enabled
        );
      })
    );
  }

  private updateProcedures(
    id?: string | string[],
    lang?: string
  ): Observable<Procedure[]> {
    return combineLatest([
      this.languageChange(),
      this.authService.authStatus.pipe(first()),
      this.membersService.member,
      this.membersService.defaultMember,
      this.networksService.resolvedNetwork,
      this.isMleEligible(),
    ]).pipe(
      debounceTime(0),
      switchMap(
        ([
          langChange,
          authChange,
          memberChange,
          defaultMember,
          network,
          mleEligible,
        ]) =>
          iif(
            () =>
              !!this.shouldRequestProcedures(
                authChange,
                memberChange,
                defaultMember,
                mleEligible
              ),
            this.doProceduresHttpRequest(
              id,
              lang || this.setRequestLocale(langChange['lang'])
            ).pipe(
              tap((procedures) => {
                if (!id) {
                  this.proceduresUpdated.next(procedures);
                }
              })
            ),
            of([]).pipe(tap(() => this.proceduresUpdated.next([])))
          )
      )
    );
  }

  private languageChange(): Observable<LangChangeEvent> {
    return this.translateService.onLangChange.pipe(
      startWith({ lang: this.translateService.currentLang, translations: {} })
    );
  }

  private getSignature(): Observable<string> {
    return this.configurationService.signatureAppParamsResolved.pipe(
      filter((sig) => !!sig),
      take(1)
    );
  }

  private isMleEligible(): Observable<boolean> {
    return this.getMleStatus().pipe(
      map((mleStatus: MleStatus) => mleStatus && mleStatus.isEligible)
    );
  }

  private getMleStatus(): Observable<MleStatus> {
    return this.mleService.status;
  }
}
