const globalHeaderHiddenRoutes = {
  mobile: [
    'home',
    'sspromo',
    'medication-finder',
    'billing-code-search',
    'coupons',
    'saved-providers',
    'activities',
  ],
  desktop: [
    'home',
    'medication-finder',
    'billing-code-search',
    'coupons',
    'saved-providers',
    'activities',
  ],
};

export default globalHeaderHiddenRoutes;
