import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-meta-information',
  templateUrl: './meta-information.component.html',
})
export class MetaInformationComponent implements OnInit, OnDestroy {
  @ViewChild('metaInfo') public metaInfo: ElementRef<HTMLElement>;

  private subscriptions = new SubscriptionManager();

  constructor(private router: Router, private focusMonitor: FocusMonitor) {}

  ngOnInit() {
    this.subscribeToNavigationEnd();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  private subscribeToNavigationEnd(): void {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && this.metaInfo) {
          this.focusMonitor.focusVia(this.metaInfo, 'program');
        }
      })
    );
  }
}
