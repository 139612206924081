import {
  Input,
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AlphaListItem } from '@services/alpha-list/alpha-list.interface.item';
import { AlphaListService } from '@services/alpha-list/alpha-list.service';

@Component({
  selector: 'app-alpha-list-menu-item',
  templateUrl: './alpha-list-menu-item.component.html',
  styleUrls: ['./alpha-list-menu-item.component.scss'],
})
export class AlphaListMenuItemComponent {
  @Input() public item: AlphaListItem;
  @Input() public index: number;

  @Output() selected: EventEmitter<AlphaListItem> = new EventEmitter();
  @Output() itemKeydown: EventEmitter<KeyboardEvent> = new EventEmitter();

  @ViewChild('alphaListOption') menuItem: ElementRef;

  constructor(public alphaListService: AlphaListService) {}

  public showAlphaLetter(i: number): boolean {
    return this.alphaListService.showLetter(i);
  }

  public focus(): void {
    this.menuItem.nativeElement?.focus();
  }
}
